/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * scrollTrigger-addTrigger.js
 *
 * 指定クラスの位置までスクロールして、指定のクラスを付与するアニメーション用
 *
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * 初期設定
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const addTrigger = document.querySelectorAll(".addtrigger");

/**
 * create
 * ".addtrigger"に".active"を付与
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
const setAddTrigger = () => {
  for (let i = 0; i < addTrigger.length; i++) {
    ScrollTrigger.create({
      trigger: addTrigger[i],
      start: "top 65%",
      toggleClass: { targets: addTrigger[i], className: "active" },
      once: true,
    });
  }
};

/**
 *  Export.
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
export { setAddTrigger };
