/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * setMobileBtn.js
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
/**
 * 初期設定
 */
const setMobileBtn = () => {
  var body = document.body;
  var mobileBtn = document.getElementById("mobile-btn");
  var mobileNav = document.getElementById("mobile-site-header-nav");
  var Links = document.querySelectorAll('#menu-d-nav a');

  // ドロワーナビオープンorクローズ
  const navToggleOpen = () => {
    body.classList.toggle("open");
    mobileBtn.classList.toggle("open");
    mobileNav.classList.toggle("open");
  }

  // ドロワーナビクローズ
  const navClose = () => {
    body.classList.remove("open");
    mobileBtn.classList.remove("open");
    mobileNav.classList.remove("open");
  }

  // ドロワーボタンクリック時
  mobileBtn.addEventListener("click", navToggleOpen);

  // アンカーリンククリック時
  Links.forEach((link) => {
    link.addEventListener("click", navClose);
  })
}
/**
 * Export.
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
export {
  setMobileBtn
};