/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * setHeaderTransform.js
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
/**
 * ヘッダー変形
 */
const body = document.body;
const contentsMain = document.getElementById("contents-main");

const setHeaderTransform = () => {
  let mainVH = contentsMain.clientHeight;

  if (window.pageYOffset > mainVH) {
    body.classList.add("transform");
  } else if (window.pageYOffset < mainVH) {
    body.classList.remove("transform");
  }
}

/**
 * ActionTo.
 */
window.addEventListener("scroll", setHeaderTransform);

export { setHeaderTransform };