/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * scrollTrigger.js
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * Import scrollTrigger.js bundle
 */
import { setAddTrigger } from "./scrollTrigger/scrollTrigger-addTrigger";
import { setparallaxImgL, setparallaxImg, setparallaxImgS } from "./scrollTrigger/scrollTrigger-parallax";
import { setTrigger } from "./scrollTrigger/scrollTrigger-trigger";
import { decrationItemAnimation } from "./scrollTrigger/scrollTrigger-animation";

/**
 * scrollTrigger
 */
const setScrollTrigger = () => {
  setAddTrigger();
  setparallaxImgL();
  setparallaxImg();
  setparallaxImgS();
  setTrigger();
  decrationItemAnimation();
};

/**
 * Export.
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
export { setScrollTrigger };
