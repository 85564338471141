/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * setSplash.js
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * Import scrollTrigger.js bundle
 */
import {
  actionToSplashHome,
  actionToSplashLower,
  actionToSplashHomeSkiped,
} from "./scrollTrigger/scrollTrigger-splash";
import { setLottie } from "./setLottie";

/**
 * videoタグ
 */
var video = document.getElementById("video");

/**
 * hintBrowser
 * will-changeを外す
 */
var willChangeItem = document.querySelectorAll(".hintBrowser");
function hintBrowser() {
  for (let i = 0; i < willChangeItem.length; i++) {
    willChangeItem[i].classList.remove("hintBrowser");
  }
}

/**
 * fadeOut.
 */
function fadeOut(node, duration) {
  node.style.opacity = 1;

  var start = performance.now();

  requestAnimationFrame(function tick(timestamp) {
    // イージング計算式（linear）
    var easing = (timestamp - start) / duration;

    // opacityが0より小さくならないように
    node.style.opacity = Math.max(1 - easing, 0);

    // イージング計算式の値が1より小さいとき
    if (easing < 1) {
      requestAnimationFrame(tick);
    } else {
      node.style.opacity = "";
      node.style.display = "none";
    }
  });
}

/**
 * スプラッシュ
 */
const setSplash = () => {
  window.addEventListener("DOMContentLoaded", () => {
    setTimeout(() => {
      if (window.document.body.id === "home") {
        var $myWindow = "visited";
        if (window.name.indexOf($myWindow) != -1) {
          document.getElementById("textSlider").style.setProperty("animation-delay", "3s");
          actionToSplashHomeSkiped();
          video.play();
        } else {
          window.name = $myWindow;
          setLottie();
          video.play();
          actionToSplashHome();
        }
      } else {
        actionToSplashLower();
      }
      hintBrowser();
    }, 100);
  });
};

/**
 * Export.
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
export { setSplash };
