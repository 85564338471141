/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * setLottie.js
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
/**
 * Lottie
 */
import lottie from "lottie-web";

/**
 * Setting.
 */
const setLottie = () => {
  // var splash = document.getElementById("splash");
  // const skipButton = document.createElement("button");
  // skipButton.id = "splash-skip";
  // skipButton.textContent = "スキップ";
  // splash.appendChild(skipButton);
  lottie.loadAnimation({
    container: document.getElementById("splash-container"),
    renderer: "svg",
    loop: false,
    autoplay: true,
    path: "/tpw-wp/wp-content/themes/tripodworks/assets/lottie/data.json",
  });
};

export { setLottie };
