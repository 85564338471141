/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * scrollTrigger-trigger.js
 *
 * 指定クラスの位置までスクロールして,実行するアニメーション用
 *
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * 初期設定
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const Trigger = document.querySelectorAll(".trigger");
const Fadein = document.querySelectorAll(".fadein");
const detailUnderline = document.querySelectorAll(
  "#contents-detail .underline .line"
);

/**
 * set
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
gsap.set(Fadein, {
  opacity: 0,
});

gsap.set(detailUnderline, {
  opacity: 0,
  scale: 0,
});

/**
 * Trigger
 * 指定クラスの位置までスクロールして,実行する
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
const setTrigger = () => {
  for (let i = 0; i < Trigger.length; i++) {
    gsap.to(Trigger[i].querySelectorAll("#contents-detail .underline .line"), {
      scale: 1,
      opacity: 1,
      duration: 1,
      delay: 0.2,
      stagger: {
        from: "start",
        amount: 0.2,
      },
      scrollTrigger: {
        trigger: Trigger[i],
        start: "top 75%",
        //   markers: true,
      },
    });
    gsap.to(Trigger[i].querySelectorAll(".fadein"), {
      opacity: 1,
      duration: 1,
      delay: 0.2,
      scrollTrigger: {
        trigger: Trigger[i],
        start: "top 75%",
        //   markers: true,
      },
    });
  }
};

/**
 * Export.
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
export { setTrigger };
