/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * scrollTrigger-parallax.js
 *
 * スクロールに合わせて実行するパララックスアニメーション用
 *
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * 初期設定
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const parallaxText = document.querySelectorAll(".parallax-text");
const parallaxImgL = document.querySelectorAll(".parallax-img.large");
const parallaxImg = document.querySelectorAll(".parallax-img.normal");
const parallaxImgS = document.querySelectorAll(".parallax-img.small");

/**
 * set
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
gsap.set(parallaxText, {
  xPercent: 5,
});

gsap.set(parallaxImgL, {
  yPercent: 35,
});

gsap.set(parallaxImg, {
  yPercent: 15,
});

gsap.set(parallaxImgS, {
  yPercent: 75,
});

/**
 * パララックス - テキスト
 * スクロールに合わせて実行するパララックス
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
const setparallaxText = () => {
  gsap.registerEffect({
    name: "parallaxText",
    effect: (targets, config) => {
      return gsap.to(targets, {
        xPercent: -5,
        scrollTrigger: {
          trigger: targets,
          start: "top bottom",
          end: "bottom top",
          scrub: 1,
          //  markers: true,
        },
      });
    },
  });

  document.querySelectorAll(".parallax-text").forEach(function (point) {
    gsap.effects.parallaxText(point);
  });
};

/**
 * パララックス - img
 * スクロールに合わせて実行するパララックス
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
const setparallaxImgL = () => {
  gsap.registerEffect({
    name: "parallaxImgL",
    effect: (targets, config) => {
      return gsap.to(targets, {
        yPercent: -10,
        scrollTrigger: {
          trigger: targets,
          start: "top bottom",
          end: "bottom top",
          scrub: 1,
          //  markers: true,
        },
      });
    },
  });

  document.querySelectorAll(".parallax-img.large").forEach(function (point) {
    gsap.effects.parallaxImgL(point);
  });
};


const setparallaxImg = () => {
  gsap.registerEffect({
    name: "parallaxImg",
    effect: (targets, config) => {
      return gsap.to(targets, {
        yPercent: -15,
        scrollTrigger: {
          trigger: targets,
          start: "top bottom",
          end: "bottom top",
          scrub: 0.5,
          //  markers: true,
        },
      });
    },
  });

  document.querySelectorAll(".parallax-img.normal").forEach(function (point) {
    gsap.effects.parallaxImg(point);
  });
};


const setparallaxImgS = () => {
  gsap.registerEffect({
    name: "parallaxImgS",
    effect: (targets, config) => {
      return gsap.to(targets, {
        yPercent: -50,
        scrollTrigger: {
          trigger: targets,
          start: "-100% bottom",
          end: "bottom 25%",
          scrub: 1,
          //  markers: true,
        },
      });
    },
  });

  document.querySelectorAll(".parallax-img.small").forEach(function (point) {
    gsap.effects.parallaxImgS(point);
  });
};

/**
 *  Export.
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
export { setparallaxImgL, setparallaxImg, setparallaxImgS };