/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * modules.js
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
/**
 * Import module.
 */
import {
  smoothScrollTo
} from "./smoothScrollTo";
import {
  setScrollTrigger
} from "./setScrollTrigger";
import {
  setSplash
} from "./setSplash";
import {
  setMegaMenuNav,
  removeClassMegaOpen
} from "./setMegaMenuNav";
import {
  setMobileBtn
} from "./setMobileBtn";
import {
  setHeaderTransform
} from "./setHeaderTransform";
import {
  tabSelecterHover
} from "./setTabSelecter";


/**
 * 導入事例投稿一覧
 */
let getPathName = location.pathname;
let pageName = /(casestudy)/g;
const casestudyPost = () => {
  if (getPathName.match(pageName)) {
    let titleArray = [];
    let tagArray = [];
    let title = document.querySelectorAll(".card .title");
    let casestudyTag = document.querySelectorAll(".card .casestudy-tag");
    let base_titleHeight = 0;
    let base_TagHeight = 0;
    let length_index_01 = window.matchMedia("(min-width: 768px)").matches ? 3 : 2;
    let length_index_02 = window.matchMedia("(min-width: 768px)").matches ? 2 : 1;

    //タイトル
    const setTitleHeight = (e, height) => {
      e.style.setProperty("height", height + "px");
    }

    title.forEach((e, i) => {
      titleArray.push(e);
      if (titleArray.length === length_index_01) {
        base_titleHeight = Math.max(...titleArray.map(e => e.clientHeight));
        titleArray.forEach(e => setTitleHeight(e, base_titleHeight));
        titleArray = [];
      } else if (titleArray.length <= length_index_02) {
        base_titleHeight = Math.max(...titleArray.map(e => e.clientHeight));
        titleArray.forEach(e => setTitleHeight(e, base_titleHeight));
      }
    })

    //カテゴリー
    if (window.matchMedia("(min-width: 768px)").matches) {
      const setTagHeight = (e, height) => {
        e.style.setProperty("height", height + "px");
      }
      casestudyTag.forEach((e, i) => {
        tagArray.push(e);
        if (tagArray.length === 3) {
          base_TagHeight = Math.max(...tagArray.map(e => e.clientHeight));
          tagArray.forEach(e => setTagHeight(e, base_TagHeight));
          tagArray = [];
        } else if (tagArray.length <= 2) {
          base_TagHeight = Math.max(...tagArray.map(e => e.clientHeight));
          tagArray.forEach(e => setTagHeight(e, base_TagHeight));
        }
      })
    }

  }
}

/**
 * Do modules.
 */
const modules = () => {
  smoothScrollTo()
  setScrollTrigger()
  setSplash()
  setMegaMenuNav()
  setMobileBtn()
  removeClassMegaOpen()
  setHeaderTransform()
  tabSelecterHover()
  casestudyPost()
};

export {
  modules
};