/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * index.js
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
/**
 * Import.
 */
import { modules } from "./module/modules";
import smoothscroll from "smoothscroll-polyfill";

/**
 * Passive Event Listener.
 */
document.addEventListener("touchstart", function () { }, { passive: true });

/**
 * 表示画面高さ取得
 */
const setFillHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
window.addEventListener("resize", setFillHeight);
setFillHeight();

/**
 * Run.
 */
modules();
smoothscroll.polyfill();

/**
 * Clear console.
 */
// console.clear();
