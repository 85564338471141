/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * scrollTrigger-splash.js
 *
 * ロード後に実行するアニメーション用
 *
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * 初期設定
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

var wrapper = document.getElementById('wrapper');
var splash = document.getElementById('splash');
var logo = document.getElementById('splash-logo');
var mv = document.getElementById('splash-mv');
var textSlider = document.getElementById('splash-text-slider');
var copy = document.getElementById('splash-copy');

/**
 * set
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
gsap.set(wrapper, {
  opacity: 0,
});

gsap.set(logo, {
  translateY: -160,
});

gsap.set(mv, {
  yPercent: -100,
});

gsap.set(textSlider, {
  xPercent: 100,
});

gsap.set(copy, {
  opacity: 0,
});

/**
 * timeline
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

var tl = gsap.timeline({
  defaults: { duration: 1, ease: "power4.out" },
});

/**
 * to
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * actionToSplashHome
 * TOPページ用スプラッシュアクション
 */
function actionToSplashHome() {
  tl.to(splash, {
    autoAlpha: 0,
    delay: 1.75,
  }, "<").to(wrapper, {
    opacity: 1,
  }).to(logo, {
    translateY: 0,
  }).to(mv, {
    yPercent: 0,
  }, "<").to(textSlider, {
    xPercent: 0,
  }, "<").to(copy, {
    opacity: 1,
    duration: 2,
  });
}

/**
 * actionToSplashHomeSkiped
 * TOPページ用スキップ後スプラッシュアクション
 */
function actionToSplashHomeSkiped() {
  tl.to(splash, {
    autoAlpha: 0,
  }, "<").to(wrapper, {
    opacity: 1,
  }).to(logo, {
    translateY: 0,
  }).to(mv, {
    yPercent: 0,
  }, "<").to(textSlider, {
    xPercent: 0,
  }, "<").to(copy, {
    opacity: 1,
    duration: 2,
  });
}

/**
 * actionToSplashLower
 * TOPページ用スプラッシュアクション
 */
function actionToSplashLower() {
  tl.to(wrapper, {
    opacity: 1,
  });
}

/**
 * Export.
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
export { actionToSplashHome, actionToSplashLower, actionToSplashHomeSkiped };
