/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * scrollTrigger-animation.js
 *
 * 
 *
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * 初期設定
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const decrationItem = document.querySelectorAll("#decration .item");

/**
 * set
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
gsap.set(decrationItem, {
    scale: 0,
    // xPercent: "random(-500, 500)",
    yPercent: "random(-250, 250)",
});

/**
 * timeline
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
const tl = gsap.timeline({
    repeat: -1,
    delay: 7,
    defaults: { duration: 0.5, ease: "power4.out" },
})

/**
 * Do animation
 */
/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
const decrationItemAnimation = () => {
    tl.from(decrationItem, {
        scale: 0,
    }).to(decrationItem, {
        scale: 1,
    }).to(decrationItem, {
        scale: 0,
        delay:3,
    }).to(decrationItem, {
        // xPercent: "random(-500, 500)",
        yPercent: "random(-250, 250)",
    }).to(decrationItem, {
        scale: 0,
    }).to(decrationItem, {
        scale: 1,
    }).to(decrationItem, {
        scale: 0,
        delay:3,
    }).to(decrationItem, {
        // xPercent: "random(-500, 500)",
        yPercent: "random(-250, 250)",
    }).to(decrationItem, {
        scale: 0,
    }).to(decrationItem, {
        scale: 1,
    }).to(decrationItem, {
        scale: 0,
        delay:3,
    }).to(decrationItem, {
        // xPercent: "random(-500, 500)",
        yPercent: "random(-250, 250)",
    })
}

export { decrationItemAnimation }