/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * setMegaMenuNav.js
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
/**
 * settings - const
 */
const body = document.body;
const MegaMenuHoverTriggers = document.querySelectorAll(".nav-menu > li");
const MegaMenu = document.getElementById("menu-mega-menu");

/**
 * settings - hover
 */
const setMegaMenuNav = () => {
  for (let i = 0; i < MegaMenuHoverTriggers.length; i++) {
    MegaMenuHoverTriggers[i].addEventListener("mouseover", (e) => {
      let currentMenu = e.currentTarget;
      if (currentMenu.classList.contains("js-mega-menu-trigger")) {
        body.classList.add("megaOpen");
      } else {
        body.classList.remove("megaOpen");
      }
    });
    MegaMenuHoverTriggers[i].addEventListener("mouseleave", (e) => {
      body.classList.remove("megaOpen");
    });
  }
  MegaMenu.addEventListener("mouseover", () => {
    body.classList.add("megaOpen");
  });
};
const removeClassMegaOpen = () => {
  MegaMenu.addEventListener("mouseleave", () => {
    body.classList.remove("megaOpen");
  });
  document.addEventListener("scroll", () => {
    body.classList.remove("megaOpen");
  });
};

document.addEventListener("DOMContentLoaded", setMegaMenuNav());

export { setMegaMenuNav, removeClassMegaOpen };