/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/

/**
 * tabSelecter.js
 */

/*━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━*/
/**
 * ページURL取得
 */
var getPathName = location.pathname;
var pageName = /(recruit)/g;

/**
 * タブ - クリック
 */
const tabSelecterClick = () => {
  if (window.document.body.id === "home" || getPathName.match(pageName)) {
    const tabTriggers = document.querySelectorAll(".js-tab-trigger");
    const tabTargets = document.querySelectorAll(".js-tab-target");

    for (let i = 0; i < tabTriggers.length; i++) {
      tabTriggers[i].addEventListener("click", (e) => {
        let currentMenu = e.currentTarget;
        let currentContent = document.getElementById(currentMenu.dataset.id);

        for (let i = 0; i < tabTriggers.length; i++) {
          tabTriggers[i].classList.remove("is-active");
        }
        currentMenu.classList.add("is-active");

        for (let i = 0; i < tabTargets.length; i++) {
          tabTargets[i].classList.remove("is-active");
        }
        if (currentContent !== null) {
          currentContent.classList.add("is-active");
        }
      });
    }
  }
};

/**
 * タブ - hover
 */
const tabSelecterHover = () => {
  const tabHoverTriggers = document.querySelectorAll(".js-tab-hover-trigger");
  const tabTargets = document.querySelectorAll(".js-tab-target");

  for (let i = 0; i < tabHoverTriggers.length; i++) {
    if (window.matchMedia("(min-width: 768px)").matches) {
      var newLocal = "mouseenter";
    } else {
      var newLocal = "touchstart";
    }
    tabHoverTriggers[i].addEventListener(newLocal, (e) => {
      let currentMenu = e.currentTarget;
      let currentContent = document.getElementById(currentMenu.dataset.id);

      for (let i = 0; i < tabHoverTriggers.length; i++) {
        tabHoverTriggers[i].classList.remove("is-active");
      }
      currentMenu.classList.add("is-active");

      for (let i = 0; i < tabTargets.length; i++) {
        tabTargets[i].classList.remove("is-active");
      }
      if (currentContent !== null) {
        currentContent.classList.add("is-active");
      }
    });
  }
};

export { tabSelecterHover };